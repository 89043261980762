import { useSession } from '@/auth/session.hook'
import { Message } from '@/backend/models/Message'
import dayjs from 'dayjs'
import React, { Fragment } from 'react'
import { ChatMessage } from './ChatMessage'

type Props = {
  conversationId: string
  messages: Message[]
  chatMessageClassName?: string
}

function ChatMessagesC(props: Props) {
  const session = useSession()
  const currentUserId = session.data?.user.id
  const myMessages = props.messages
    .filter((m) => m.type !== 'client-data')
    .filter((m) => m.userId === currentUserId)

  const myFirstMessageId = myMessages[0]?.id
  const myMessagesCount = myMessages.length

  return props.messages.map((msg, idx) => {
    const prevMsg = props.messages[idx - 1]
    const nextMsg = props.messages[idx + 1]

    const isDateDiff = nextMsg
      ? dayjs(msg.createdAt).format('YYYYMMDD') !==
        dayjs(nextMsg.createdAt).format('YYYYMMDD')
      : true

    let unreadCount = 0
    for (let i = idx; i >= 0; i--) {
      if (
        props.messages[i].isRead === false &&
        props.messages[i].userId === msg.userId
      ) {
        unreadCount++
      } else {
        break
      }
    }

    return (
      <Fragment key={msg.id}>
        <ChatMessage
          unreadCount={unreadCount}
          myMessagesCount={myMessagesCount}
          myFirstMessageId={myFirstMessageId}
          className={props.chatMessageClassName}
          conversationId={props.conversationId}
          message={msg}
          siblings={{
            prev: prevMsg,
            next: nextMsg,
          }}
        />
        {isDateDiff && (
          <div className="my-16 flex items-center justify-center">
            <span className="text-12 font-[400] text-txt-secondary">
              {dayjs(msg.createdAt).format('YYYY년 MM월 DD일')}
            </span>
          </div>
        )}
      </Fragment>
    )
  })
}

export const ChatMessages = React.memo(ChatMessagesC)
