import { cn } from '@/app/_primitives/utils/cn'
import Image from 'next/image'

import { UserInfo } from '@/app/_messenger/common/ChatMessage'

export function ProfilePair(props: {
  userIds: Array<string | undefined | null>
  images: Array<string | undefined | null>
  fallback: string
}) {
  return (
    <div className="flex shrink-0 flex-row items-center">
      {props.images.map((image, index) => (
        <UserInfo userId={props.userIds[index] || ''} key={index}>
          <Image
            alt=""
            width={37}
            height={37}
            className={cn(
              'rounded-full object-cover border border-[#ffffff] bg-white',
              {
                '-ml-16': index > 0,
              },
            )}
            src={image || props.fallback}
          />
        </UserInfo>
      ))}
    </div>
  )
}
