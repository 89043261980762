import { fetchTempSignedURL } from '@/backend/apis/storage/GetTempSignedURL'
import { createStorageHook } from '@/libraries/storage/createStorageHook'

export const { useTempUpload, useTempUploadBulk } = createStorageHook({
  async getSignedURL(params) {
    const response = await fetchTempSignedURL(params)

    return response.status === 'ok' ? response.data : null
  },
})
