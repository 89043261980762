import { reportChat } from '@/app/_messenger/actions'
import { useMessengerState } from '@/app/_messenger/hooks/useMessengerState'
import { Button } from '@/app/_primitives/Button'
import { Dialog, DialogContent, DialogTrigger } from '@/app/_primitives/Dialog'
import { InputLabel } from '@/app/_primitives/InputLabel'
import { TextArea } from '@/app/_primitives/TextArea'
import { toast } from '@/app/_primitives/Toast'
import { Close } from '@radix-ui/react-dialog'
import { useMutation } from '@tanstack/react-query'
import { X } from 'lucide-react'
import { ReactNode, useState } from 'react'

function RadioButton(props: {
  children: ReactNode
  value: string
  name: string
  onCheck: (value: string) => void
}) {
  return (
    <label className={'flex flex-row items-center gap-12'}>
      <input
        type={'radio'}
        name={props.name}
        value={props.value}
        onClick={() => {
          props.onCheck(props.value)
        }}
        className={'size-24 rounded-full border-french-bleu accent-french-bleu'}
      />
      {props.children}
    </label>
  )
}

export function ReportToAdminPopup(props: {
  children: ReactNode
  closeMenu: () => void
}) {
  const [data, setData] = useState({
    reason: '',
    description: '',
  })
  const [state] = useMessengerState()
  const [open, setOpen] = useState(false)
  const mutation = useMutation({
    mutationFn: (
      vars: typeof data & {
        conversationId: string
      },
    ) => reportChat(vars.conversationId, vars.reason, vars.description),
    onSuccess: () => {
      setOpen(false)
      props.closeMenu()
      setData({
        reason: '',
        description: '',
      })

      toast.success({
        title: '신고가 완료되었습니다.',
        content: '',
      })
    },
  })

  const handleSubmit = () => {
    if (!state.selectedConversationId) return
    if (data.reason === '') return
    if (mutation.isPending) return

    mutation.mutate({
      ...data,
      conversationId: state.selectedConversationId,
    })
  }

  return (
    <Dialog
      open={open}
      onOpenChange={(o) => {
        if (!o) props.closeMenu()

        setOpen(o)
      }}>
      <DialogTrigger>{props.children}</DialogTrigger>
      <DialogContent
        className={
          'flex w-422 flex-col items-stretch gap-24 !rounded-20 border-0 p-40'
        }
        asChild>
        <form
          onSubmit={(e) => {
            e.preventDefault()

            handleSubmit()
          }}>
          <div className={'flex flex-row items-center justify-between'}>
            <p className={'c-b1 font-[700] text-[#1a1a1a]'}>채팅 신고하기</p>
            <Close disabled={mutation.isPending}>
              <X className={'size-24'} />
            </Close>
          </div>
          <p className={'c-b4 -mt-16 text-[#8b8b8b]'}>
            상대방의 모든 메시지가 차단되며, <br />
            신고 처리를 위해 대화 내용이 이너부스팀에 전송됩니다.
          </p>

          <InputLabel label={'신고 사유'} variant={'dark'}>
            <div className={'flex flex-col gap-10'}>
              <RadioButton
                onCheck={(value) =>
                  setData({
                    ...data,
                    reason: value,
                  })
                }
                value={'스팸 및 광고성 메시지'}
                name={'reason'}>
                <p className={'text-base font-[500]'}>스팸 및 광고성 메시지</p>
              </RadioButton>
              <RadioButton
                onCheck={(value) =>
                  setData({
                    ...data,
                    reason: value,
                  })
                }
                value={'도배 또는 비속어, 인신공격'}
                name={'reason'}>
                <p className={'text-base font-[500]'}>
                  도배 또는 비속어, 인신공격
                </p>
              </RadioButton>
              <RadioButton
                onCheck={(value) =>
                  setData({
                    ...data,
                    reason: value,
                  })
                }
                value={'사칭 또는 허위 사실 유포'}
                name={'reason'}>
                <p className={'text-base font-[500]'}>
                  사칭 또는 허위 사실 유포
                </p>
              </RadioButton>
              <RadioButton
                onCheck={(value) =>
                  setData({
                    ...data,
                    reason: value,
                  })
                }
                value={'현금거래 유도'}
                name={'reason'}>
                <p className={'text-base font-[500]'}>현금거래 유도</p>
              </RadioButton>
              <RadioButton
                onCheck={(value) =>
                  setData({
                    ...data,
                    reason: value,
                  })
                }
                value={'기타'}
                name={'reason'}>
                <p className={'text-base font-[500]'}>기타</p>
              </RadioButton>
            </div>
          </InputLabel>

          <InputLabel label={'신고 이유 및 설명'} variant={'dark'}>
            <TextArea
              value={data.description}
              onChange={(e) =>
                setData({
                  ...data,
                  description: e.target.value,
                })
              }
              placeholder={
                '부적절한 신고 및 허위 신고의 경우 운영원칙에 따라 제재를 받을 수 있습니다.'
              }
            />
          </InputLabel>

          <div className={'flex flex-row items-center justify-end'}>
            <Button
              loading={mutation.isPending}
              type={'submit'}
              variant={'filled'}
              size={'sm'}>
              신고하기
            </Button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  )
}
