import { statusUpdate } from '@/app/_messenger/hooks/useWebSocket'
import { useEffect, useState } from 'react'

export function useTypingStatus(conversationId: string | null) {
  const [typing, setTyping] = useState(-1)

  useEffect(() => {
    if (typing === -1) return

    const timeout = setTimeout(() => {
      setTyping(-1)
      conversationId && statusUpdate('none', conversationId)
    }, 300)

    return () => {
      clearTimeout(timeout)
    }
  }, [typing, conversationId])

  return () => {
    if (typing === -1) {
      conversationId && statusUpdate('typing', conversationId)
    }

    setTyping(Math.random())
  }
}
