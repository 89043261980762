import { useQueryClient } from '@tanstack/react-query'
import { useCallback } from 'react'

export function useTriggerUpdateConversations() {
  const queryClient = useQueryClient()

  return useCallback(
    function () {
      queryClient
        .refetchQueries({
          queryKey: ['conversations:unread-count'],
        })
        .then()
      queryClient
        .refetchQueries({
          queryKey: ['conversations:page:admin', 'all'],
        })
        .then()
      queryClient
        .refetchQueries({
          queryKey: ['conversations:page', 'all'],
        })
        .then()
      queryClient
        .refetchQueries({
          queryKey: ['conversations', 'all'],
        })
        .then()
      queryClient
        .refetchQueries({
          queryKey: ['conversations', 'unread'],
        })
        .then()
      queryClient
        .refetchQueries({
          queryKey: ['conversations', 'read'],
        })
        .then()
    },
    [queryClient],
  )
}
