'use client'

import { cn } from '@/app/_primitives/utils/cn'
import { ClientDataSchema } from '@/backend/models/Message'
import dayjs from 'dayjs'
import { atom, useAtom } from 'jotai'
import { ReactNode } from 'react'
import { z } from 'zod'

type ClientData = z.infer<typeof ClientDataSchema>

export const clientDataPreviewAtom = atom<ClientData | null>(null)

function Data(props: { label: string; value: ReactNode }) {
  return (
    <div
      className={`flex flex-row items-start gap-8 ${props.label === '궁금한 점' && 'flex-col'}`}>
      <p className="c-h7 w-120 shrink-0">{props.label}</p>
      {props.value}
    </div>
  )
}

export function ClientDataPreview(props: {
  className?: string
  admin?: boolean
}) {
  const [state, setState] = useAtom(clientDataPreviewAtom)

  if (state === null) return null

  return (
    <div className="absolute top-0 z-[100] flex size-full flex-col items-stretch justify-end rounded-r-20 bg-[#00000033]">
      <div onClick={() => setState(null)} className="flex-1 cursor-pointer" />
      <div
        id="client-chat"
        className={cn(
          'rounded-20 bg-white p-24 shrink-0',
          'flex flex-col items-stretch gap-24 overflow-y-auto',
          `${!props.admin && 'max-h-[400px]'}`,
          props.className,
        )}>
        <div className="flex flex-row items-center justify-between">
          <p className="c-b2">문의 내용을 확인해주세요.</p>
          <svg
            className="cursor-pointer"
            onClick={() => setState(null)}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M6.7072 5.29289C6.31668 4.90237 5.68351 4.90237 5.29299 5.29289C4.90246 5.68342 4.90246 6.31658 5.29299 6.70711L10.5858 12L5.29289 17.2929C4.90237 17.6834 4.90237 18.3166 5.29289 18.7071C5.68342 19.0976 6.31658 19.0976 6.70711 18.7071L12 13.4142L17.293 18.7071C17.6835 19.0976 18.3167 19.0976 18.7072 18.7071C19.0977 18.3166 19.0977 17.6834 18.7072 17.2929L13.4143 12L18.7071 6.70711C19.0976 6.31658 19.0976 5.68342 18.7071 5.29289C18.3166 4.90237 17.6834 4.90237 17.2929 5.29289L12 10.5857L6.7072 5.29289Z"
              fill="black"
            />
          </svg>
        </div>

        <div
          className={`flex flex-col items-stretch gap-10 overflow-auto ${!props.admin && 'max-h-200'}`}>
          <Data label={'기업명'} value={<p className="c-b3">{state.name}</p>} />
          <Data
            label={'개발 상품'}
            value={<p className="c-b3">{state.product}</p>}
          />
          <Data
            label={'예상 출시 시기'}
            value={
              <p className="c-b3">{dayjs(state.date).format('YYYY년 MM월')}</p>
            }
          />
          <Data
            label={'궁금한 점'}
            value={
              <p className="c-b4 mt-10 max-h-222 w-full flex-1 whitespace-pre-wrap rounded-16 border border-tertiary px-20 py-13">
                <span
                  id="client-chat__desc"
                  className="flex h-full flex-col overflow-y-auto">
                  {state.information}
                </span>
              </p>
            }
          />
        </div>

        <button
          type="button"
          onClick={() => setState(null)}
          className="c-h8 h-48 rounded-full bg-french-bleu text-white outline-none focus-visible:outline-none">
          답변하기
        </button>
      </div>
    </div>
  )
}
