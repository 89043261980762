import { ZodEnum } from 'zod'

type TStringArray = [string, ...string[]]
type TIndexedObject<T extends TStringArray, V = string> = {
  [key in T[number]]: V
}

export const createEnum = <T extends TStringArray>(
  zEnum: ZodEnum<T>,
  labels?: TIndexedObject<T>,
) => {
  const label = (key: T[number]) => labels?.[key] || key

  const toArray = (exclude?: T[number][]) =>
    zEnum.options
      .filter((value) => !exclude?.includes(value))
      .map((value) => ({
        value,
        label: label(value),
      }))

  const assert = (value: string): T[number] => value

  const value = (key: T[number]) => key

  return {
    zEnum,
    toArray,
    assert,
    value,
    label,
  }
}

export const withValues = <T extends TStringArray, V>(
  createEnumResult: ReturnType<typeof createEnum<T>>,
  values: TIndexedObject<TStringArray, V>,
) => {
  const value = (key: T[number]) => values?.[key]

  const toArray = () =>
    createEnumResult.zEnum.options.map((_value) => ({
      value: value(_value),
      label: createEnumResult.label(_value),
    }))

  return {
    ...createEnumResult,
    toArray,
    value,
  }
}
