import { fetchPaginatedMessages } from '@/app/_messenger/actions'
import { useMessengerState } from '@/app/_messenger/hooks/useMessengerState'
import { useTriggerUpdateConversations } from '@/app/_messenger/hooks/useTriggerUpdateConversations'
import { useSession } from '@/auth/session.hook'
import { PaginatedMessages } from '@/backend/models/Message'
import { useInfiniteQuery } from '@tanstack/react-query'
import dayjs from 'dayjs'
import { useCallback, useLayoutEffect, useMemo, useRef } from 'react'
import useInfiniteScroll from 'react-infinite-scroll-hook'

function useChatMessages() {
  const session = useSession()
  const [{ selectedConversationId: conversationId }] = useMessengerState()

  const triggerUpdate = useTriggerUpdateConversations()

  const query = useInfiniteQuery<PaginatedMessages>({
    enabled: conversationId !== null,
    queryKey: ['messages', conversationId],
    async queryFn({ pageParam = null }) {
      const cursor = typeof pageParam === 'string' ? pageParam : undefined

      const result = await fetchPaginatedMessages({
        conversationId: conversationId!,
        limit: 15,
        cursor,
      })

      if (!cursor) {
        triggerUpdate()
      }

      return {
        data: result.data.map((msg) => ({
          ...msg,
          createdAt: dayjs.utc(msg.createdAt).toDate(),
        })),
        meta: result.meta,
      }
    },
    getNextPageParam: (lastPage) => lastPage?.meta.nextCursor ?? undefined,
    initialPageParam: null,
  })

  const messages = useMemo(
    () =>
      session.status !== 'authenticated'
        ? []
        : query.data?.pages.flatMap((page) => page.data) ?? [],
    [query.data, session.status],
  )

  return {
    query,
    messages,
  }
}

export function useSelectedConversation() {
  const [{ selectedConversationId: conversationId }] = useMessengerState()
  const { query, messages } = useChatMessages()

  const [ref, { rootRef }] = useInfiniteScroll({
    loading: query.isFetchingNextPage,
    hasNextPage: query.hasNextPage,
    onLoadMore: query.fetchNextPage,
    disabled: false,
  })

  const scrollableRootRef = useRef<HTMLDivElement | null>(null)
  const lastScrollDistanceToBottomRef = useRef<number>()

  const scrollToLastMessage = useCallback(() => {
    const scrollableRoot = scrollableRootRef.current
    const lastScrollDistanceToBottom =
      lastScrollDistanceToBottomRef.current ?? 0
    if (scrollableRoot) {
      scrollableRoot.scrollTop =
        scrollableRoot.scrollHeight - lastScrollDistanceToBottom
    }
  }, [])

  useLayoutEffect(() => {
    scrollToLastMessage()
  }, [messages, rootRef, scrollToLastMessage])

  return {
    query,
    messages,
    ref,
    rootRef,
    scrollableRootRef,
    lastScrollDistanceToBottomRef,
    conversationId,
    scrollToLastMessage,
  }
}
