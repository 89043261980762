'use client'

import Skeleton from '@/app/_components/Shared/Skeleton'
import { ConversationItem } from '@/app/_messenger/common/ConversationItem'
import { useConversations } from '@/app/_messenger/hooks/useConversations'
import { useMessengerState } from '@/app/_messenger/hooks/useMessengerState'
import { ClientDataSchema } from '@/backend/models/Message'
import { getUserAvatarURL } from '@/libraries/avatar'
import Image from 'next/image'
import React from 'react'
import EmptyImg from './Empty.svg'

export function Conversations({
  className,
  key = 'conversations',
  tab,
  isAdmin = false,
}: {
  className?: string
  key?: string
  tab?: 'all' | 'unread' | 'read'
  isAdmin?: boolean
}) {
  const [state] = useMessengerState()
  const { query, ref, rootRef } = useConversations(
    key,
    tab || state.currentTab,
    isAdmin,
  )

  const totalMessages = query.data?.pages.flatMap((page) => page.data).length

  if (totalMessages === 0) {
    return (
      <div className={className}>
        <div className="flex h-full flex-col items-center justify-center gap-38">
          <p className="c-b3 text-[#3c3c3c]">아직 주고받은 메시지가 없어요.</p>
          <Image src={EmptyImg} alt="Empty" width={103} height={126} />
        </div>
      </div>
    )
  }

  return (
    <div ref={rootRef} className={className}>
      {query.data?.pages.map((page, i) => (
        <React.Fragment key={i}>
          {page.data.map((conversation) => {
            let lastMessage =
              conversation.lastMessageType === 'file'
                ? conversation.lastMessageFileName || ''
                : conversation.lastMessageContent || ''

            if (conversation.lastMessageType === 'client-data') {
              try {
                const clientData = ClientDataSchema.parse(
                  JSON.parse(lastMessage),
                )

                lastMessage = `${clientData.name}님으로부터캐릭터 콜라보 문의가 들어왔어요 😄`
              } catch (e) {
                lastMessage = 'N/A'
              }
            }

            const users = Object.entries(conversation.userMap)
              .map((entry) => entry[1])
              .map((u) => ({
                id: u.id,
                displayName: u.name,
                imageURL: u.thumbnail,
                type: u.type,
              }))

            const creator = users.find((u) => u.type !== 'user')
            const user = users.find((u) => u.id != creator?.id)

            const hasUnreadMessages = isAdmin
              ? false
              : (conversation.unreadCount || 0) > 0

            return (
              <ConversationItem
                key={conversation.id}
                id={conversation.id}
                imageURL={conversation.imageURL || getUserAvatarURL('')}
                userIds={[creator?.id, user?.id]}
                imageURLs={[creator?.imageURL, user?.imageURL]}
                adminIntentDisplayName={`${creator?.displayName}와 ${user?.displayName || 'N/A'}님의 대화
`}
                displayName={conversation.displayName || 'N/A'}
                lastMessage={lastMessage}
                updatedAt={conversation.updatedAt}
                hasUnreadMessages={hasUnreadMessages}
              />
            )
          })}
        </React.Fragment>
      ))}
      {(query.isFetchingNextPage || query.isLoading) && (
        <div className="px-24 py-12">
          <Skeleton className="h-48 w-full rounded-xl" />
        </div>
      )}
      {!query.isFetchingNextPage && query.hasNextPage && (
        <div ref={ref} className="px-24 py-12">
          <Skeleton className="h-48 w-full rounded-xl" />
        </div>
      )}
    </div>
  )
}
