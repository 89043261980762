'use client'

import { LeaveChatPopup } from '@/app/_messenger/common/LeaveChatPopup'
import { ReportToAdminPopup } from '@/app/_messenger/common/ReportToAdminPopup'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/app/_primitives/Popover'
import { cn } from '@/app/_primitives/utils/cn'
import { ReactNode, useState } from 'react'

function MenuItem(props: { children: ReactNode }) {
  return (
    <p
      className={
        'cursor-pointer rounded-15 px-24 py-12 text-start text-base hover:bg-[#3366B61A]'
      }>
      {props.children}
    </p>
  )
}

export function ConversationOptionsMenu(props: { iconClassName?: string }) {
  const [open, setOpen] = useState(false)
  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <svg
          className={cn(props.iconClassName)}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="#3366B6"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M8 12C8 13.1046 7.10457 14 6 14C4.89543 14 4 13.1046 4 12C4 10.8954 4.89543 10 6 10C7.10457 10 8 10.8954 8 12Z"
            fill="currentFill"
          />
          <path
            d="M14 12C14 13.1046 13.1046 14 12 14C10.8954 14 10 13.1046 10 12C10 10.8954 10.8954 10 12 10C13.1046 10 14 10.8954 14 12Z"
            fill="currentFill"
          />
          <path
            d="M20 12C20 13.1046 19.1046 14 18 14C16.8954 14 16 13.1046 16 12C16 10.8954 16.8954 10 18 10C19.1046 10 20 10.8954 20 12Z"
            fill="currentFill"
          />
        </svg>
      </PopoverTrigger>
      <PopoverContent className={'flex flex-col items-stretch p-12'}>
        <LeaveChatPopup closeMenu={() => setOpen(false)}>
          <MenuItem>채팅방 나가기</MenuItem>
        </LeaveChatPopup>
        <ReportToAdminPopup closeMenu={() => setOpen(false)}>
          <MenuItem>신고하기</MenuItem>
        </ReportToAdminPopup>
      </PopoverContent>
    </Popover>
  )
}
