import { leaveChat } from '@/app/_messenger/actions'
import { useMessengerState } from '@/app/_messenger/hooks/useMessengerState'
import { useTriggerUpdateConversations } from '@/app/_messenger/hooks/useTriggerUpdateConversations'
import { Button } from '@/app/_primitives/Button'
import { Dialog, DialogContent, DialogTrigger } from '@/app/_primitives/Dialog'
import { Close } from '@radix-ui/react-dialog'
import { useMutation } from '@tanstack/react-query'
import { X } from 'lucide-react'
import { ReactNode, useState } from 'react'

export function LeaveChatPopup(props: {
  children: ReactNode
  closeMenu: () => void
}) {
  const triggerUpdate = useTriggerUpdateConversations()
  const [state, setState] = useMessengerState()
  const [open, setOpen] = useState(false)
  const mutation = useMutation({
    mutationFn: (convId: string) => leaveChat(convId),
    onSuccess: () => {
      setState((draft) => {
        const url = new URL(window.location.href)
        url.searchParams.delete('conversation')
        url.searchParams.delete('conversationName')

        if (typeof window !== 'undefined')
          window.history.replaceState(null, '', url.toString())

        draft.selectedConversationId = null
      })
      setOpen(false)
      props.closeMenu()

      triggerUpdate()
    },
  })

  const handleSubmit = async () => {
    if (!state.selectedConversationId) return
    if (mutation.isPending) return

    mutation.mutate(state.selectedConversationId)
  }

  return (
    <Dialog
      open={open}
      onOpenChange={(o) => {
        if (!o) props.closeMenu()
        setOpen(o)
      }}>
      <DialogTrigger>{props.children}</DialogTrigger>
      <DialogContent
        className={
          'flex w-433 flex-col items-stretch gap-8 !rounded-20 border-0 p-20'
        }>
        <div className={'flex flex-row items-center justify-between'}>
          <p className={'c-b2 text-[#1a1a1a]'}>대화를 종료하시겠습니까?</p>
          <Close disabled={mutation.isPending}>
            <X className={'size-24'} />
          </Close>
        </div>
        <p className={'c-b4 mb-4 text-txt-secondary'}>
          종료 시 채팅 목록에서 사라져요.
          <br />
          동일한 상대방과 다시 대화를 시작하면
          <br />
          이전 내용을 다시 확인할 수 있어요.
          <br />
        </p>
        <div className={'flex flex-row items-center justify-end gap-8'}>
          <Close asChild>
            <Button
              disabled={mutation.isPending}
              type={'button'}
              variant={'filled'}
              size={'sm'}
              color={'white'}>
              취소
            </Button>
          </Close>
          <Button
            loading={mutation.isPending}
            onClick={handleSubmit}
            type={'button'}
            variant={'filled'}
            size={'sm'}>
            종료
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  )
}
