import { SvgIconProps } from '@/types/helpers'

export default function Send(props: SvgIconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="#D9DADB"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M6.29602 2.90578C3.91012 1.8046 1.36183 4.08543 2.19279 6.57834L3.45424 10.3194C3.59136 10.7261 3.97267 10.9999 4.40182 10.9999H13C13.5523 10.9999 14 11.4476 14 11.9999C14 12.5522 13.5523 12.9999 13 12.9999H4.40182C3.97267 12.9999 3.59136 13.2737 3.45424 13.6804L2.19281 17.4215C1.36183 19.9144 3.91012 22.1952 6.29603 21.0941L20.0983 14.7238C22.422 13.6513 22.422 10.3486 20.0983 9.27607L6.29602 2.90578Z"
        fill="currentFill"
      />
    </svg>
  )
}
