'use client'

import { SendMessageForm } from '@/app/_messenger/common/SendMessageForm'
import { useMessengerState } from '@/app/_messenger/hooks/useMessengerState'
import { Message } from '@/backend/models/Message'
import { useQueryClient } from '@tanstack/react-query'
import { useClientData } from './ClientData'

export function SendMessageFormWrapper(props: {
  messageCount: number
  secondMessage?: Message
  backdropTop: number
}) {
  const [{ selectedConversationId: conversationId }] = useMessengerState()
  const qc = useQueryClient()
  const messagesData = qc.getQueryData(['messages', conversationId])

  const { condition, component, formfilled } = useClientData({
    conversationId: conversationId,
    messageCount: props.messageCount,
    message: props.secondMessage,
    backdropTop: props.backdropTop,
  })

  if (!conversationId) return null
  if (condition) return component

  return (
    <>
      {formfilled && component}
      <div className="flex shrink-0 border-t border-tertiary bg-white px-24 py-12">
        <SendMessageForm disabled={!messagesData} />
      </div>
    </>
  )
}
