import {
  fetchConversation,
  fetchPaginatedConversations,
  fetchPaginatedConversationsForAdmin,
} from '@/app/_messenger/actions'
import { PaginatedConversations } from '@/backend/models/Conversation'
import { useInfiniteQuery, useQuery } from '@tanstack/react-query'
import useInfiniteScroll from 'react-infinite-scroll-hook'
import { useMessengerState } from './useMessengerState'

export function useConversations(
  key: string = 'conversations',
  tab: 'all' | 'unread' | 'read' = 'all',
  isAdmin = false,
) {
  const fetcher = isAdmin
    ? fetchPaginatedConversationsForAdmin
    : fetchPaginatedConversations
  const primaryKey = isAdmin ? `${key}:admin` : key
  const query = useInfiniteQuery<PaginatedConversations>({
    queryKey: [primaryKey, tab],
    async queryFn({ pageParam = null }) {
      return fetcher({
        limit: 10,
        filter: tab,
        cursor: typeof pageParam === 'string' ? pageParam : undefined,
      })
    },
    getNextPageParam: (lastPage) => lastPage?.meta.nextCursor ?? undefined,
    initialPageParam: null,
  })

  const [ref, { rootRef }] = useInfiniteScroll({
    loading: query.isFetchingNextPage,
    hasNextPage: query.hasNextPage,
    onLoadMore: query.fetchNextPage,
    disabled: false,
  })

  return { query, ref, rootRef }
}

export function useConversation() {
  const [{ selectedConversationId: id }] = useMessengerState()

  return useQuery({
    queryKey: ['conversation', id],
    async queryFn() {
      return fetchConversation(id || '')
    },
    enabled: !!id,
    staleTime: 1000 * 60 * 5,
  })
}
