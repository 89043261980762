import { channel } from '@/app/_messenger/hooks/useWebSocket'
import { atomWithImmer } from 'jotai-immer'
import { useAtom } from 'jotai/index'

const messengerAtom = atomWithImmer<{
  open: boolean

  selectedConversationId: string | null
  currentTab: 'all' | 'unread' | 'read'

  isWssReady: boolean
  isOtherUserTyping: boolean
}>({
  open: false,
  selectedConversationId: null,
  currentTab: 'all',
  isWssReady: false,
  isOtherUserTyping: false,
})

messengerAtom.onMount = (set) => {
  if (typeof window === 'undefined') return

  const url = new URL(window.location.href)

  const conversation = url.searchParams.get('conversation') || null

  conversation && channel(conversation, 'join')

  set((draft) => {
    draft.selectedConversationId = conversation
  })
}

export function useMessengerState() {
  return useAtom(messengerAtom)
}
