'use client'

import { createContext, useContext } from 'react'

const IntentContext = createContext<'default' | 'admin'>('default')

export function Intent(props: {
  intent: 'default' | 'admin'
  children: React.ReactNode
}) {
  return (
    <IntentContext.Provider value={props.intent}>
      {props.children}
    </IntentContext.Provider>
  )
}

export function useIntent() {
  return useContext(IntentContext)
}
