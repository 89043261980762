import { MutationOptions, useMutation } from '@tanstack/react-query'
import { nanoid } from 'nanoid'

type UploadVars = {
  file: File
  path?: string
}

type UploadBulkVars = {
  files: File[]
  path?: string
}

class UploadError {
  readonly _tag = 'UploadError'

  public constructor(
    public readonly type: 'GET_UPLOAD_URL' | 'PUT_OBJECT_REQUEST_ERROR',
    public readonly error: unknown,
  ) {}
}

export function createStorageHook(params: {
  getSignedURL: (params: {
    key: string
    mimeType: string
    originalFileName: string
  }) => Promise<string | null>
}) {
  async function sendPORequest(url: string, file: File) {
    const dispositionHeader = `attachment; filename*=UTF-8''${encodeURIComponent(
      file.name,
    )}`

    try {
      const response = await fetch(url, {
        method: 'PUT',
        body: file,
        headers: {
          'Content-Disposition': dispositionHeader,
        },
      })

      if (response.status !== 200) {
        throw await response.text()
      }
    } catch (e) {
      throw new UploadError('PUT_OBJECT_REQUEST_ERROR', e)
    }
  }

  async function mutationFunction({ file, path }: UploadVars) {
    const mimeType = file.type
    const extension = mimeType.split('/')[1]
    const fileName = `${nanoid()}.${extension}`

    const key = path ? [path, fileName].join('/') : fileName
    const originalFileName = encodeURIComponent(file.name)

    const url = await params.getSignedURL({
      key,
      mimeType,
      originalFileName,
    })

    if (!url) throw new UploadError('GET_UPLOAD_URL', null)

    await sendPORequest(url, file)

    return `temp/${key}`
  }

  function useTempUpload(
    options?: MutationOptions<string, UploadError, UploadVars>,
  ) {
    return useMutation<string, UploadError, UploadVars>({
      mutationFn: mutationFunction,
      ...options,
    })
  }

  function useTempUploadBulk(
    options?: MutationOptions<string[], UploadError, UploadBulkVars>,
  ) {
    return useMutation<string[], UploadError, UploadBulkVars>({
      async mutationFn({ files, path }) {
        const uploadPromises = files.map((file) =>
          mutationFunction({ file, path }),
        )

        return Promise.all(uploadPromises)
      },
      ...options,
    })
  }

  return { useTempUpload, useTempUploadBulk }
}
