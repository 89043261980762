import { useMessengerState } from '@/app/_messenger/hooks/useMessengerState'
import { Loader } from '@/app/_primitives/Loader'
import { cn } from '@/app/_primitives/utils/cn'
import { useLayoutEffect } from 'react'

export function TypingIndicator({
  scroll,
  className,
}: {
  className?: string
  scroll: () => void
}) {
  const [state] = useMessengerState()

  useLayoutEffect(() => {
    if (state.isOtherUserTyping) {
      scroll()
    }
  }, [state.isOtherUserTyping, scroll])

  if (!state.isOtherUserTyping) return null

  return (
    <div className={cn('flex gap-8', className)}>
      <div className="relative aspect-square size-48 shrink-0 overflow-hidden rounded-full" />
      <div
        className={
          'other-user-bubble flex h-48 w-99 flex-row items-center justify-center rounded-full bg-[rgba(0,_0,_0,_0.04)]'
        }>
        <Loader
          variantConfig={{
            '--lv1-color': '#0000004D',
          }}
          variant={'variant1'}
        />
      </div>
    </div>
  )
}
