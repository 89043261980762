import { SvgIconProps } from '@/types/helpers'

export default function FileAdd(props: SvgIconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="#D9DADB"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 5C3 3.34315 4.34315 2 6 2H15.2574C16.053 2 16.8161 2.31607 17.3787 2.87868L20.1213 5.62132C20.6839 6.18393 21 6.94699 21 7.74264V19C21 20.6569 19.6569 22 18 22H6C4.34315 22 3 20.6569 3 19V5ZM15 6.00001V4.20712C15 3.76167 15.5386 3.53858 15.8536 3.85356L19.1464 7.14646C19.4614 7.46144 19.2383 8.00001 18.7929 8.00001H17C15.8954 8.00001 15 7.10458 15 6.00001ZM12 9C12.5523 9 13 9.44772 13 10V12H15C15.5523 12 16 12.4477 16 13C16 13.5523 15.5523 14 15 14H13L13 16C13 16.5523 12.5523 17 12 17C11.4477 17 11 16.5523 11 16L11 14H9C8.44772 14 8 13.5523 8 13C8 12.4477 8.44772 12 9 12H11V10C11 9.44772 11.4477 9 12 9Z"
        fill="currentFill"
      />
    </svg>
  )
}
