import { getConversationCharacter } from '@/app/_messenger/actions'
import { useQuery } from '@tanstack/react-query'
import Image from 'next/image'
import Link from 'next/link'

export function ConversationCharacter({
  conversationId,
}: {
  conversationId: string
}) {
  const { data } = useQuery({
    queryKey: ['conversation-character', conversationId],
    queryFn: () => getConversationCharacter(conversationId),
  })

  if (!data) return null

  return (
    <Link
      className={'w-full min-w-260 overflow-hidden rounded-20'}
      target={'_blank'}
      href={`/characters/${data.humanReadableId}`}>
      <div
        className={'relative h-182 w-full'}
        style={{
          background: data.color || 'white',
        }}>
        <Image
          src={data.imageURL}
          alt={'img'}
          fill
          className={'object-cover'}
        />
      </div>
      <div className={'flex flex-col gap-10 bg-[#f5f5f5] p-16'}>
        <p className={'c-h7 text-[#162220]'}>{data.name}</p>
        <p className={'c-b4 text-[#9e9e9e]'}>
          inabooth.io/characters/{data.humanReadableId}
        </p>
      </div>
    </Link>
  )
}
