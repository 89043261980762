import CloudDownload from '@/app/_components/Icon/CloudDownload'
import { cn } from '@/app/_primitives/utils/cn'
import { ClientDataSchema, Message } from '@/backend/models/Message'
import { filesize } from 'filesize'
import { useAtom } from 'jotai'
import Link from 'next/link'
import { useMemo } from 'react'
import { clientDataPreviewAtom } from './ClientDataPreview'

export function ChatMessageBubble({
  className,
  message,
  variant,
}: {
  message: Message
  variant: 'left' | 'right'
  className?: string
}) {
  const [, setState] = useAtom(clientDataPreviewAtom)
  const fileSize = useMemo(
    () => filesize(message.fileSize ?? 0, { standard: 'jedec' }),
    [message],
  )

  const content = useMemo(() => {
    switch (message.type) {
      case 'text': {
        return message.content
      }
      case 'file': {
        return (
          <Link
            className="flex justify-between"
            href={message.content}
            target="_blank">
            <div className={'flex justify-between gap-10'}>
              <div className="flex flex-col gap-4">
                <span className={'c-b4 text-[rgba(48,_48,_48,_1)]'}>
                  {message.fileName}
                </span>
                <span className={'c-b4 text-[rgba(139,_139,_139,_1)]'}>
                  {message.fileType} {fileSize}
                </span>
              </div>

              <div
                className={
                  'flex size-40 shrink-0 items-center justify-center rounded-full bg-white'
                }>
                <CloudDownload />
              </div>
            </div>
          </Link>
        )
      }
      case 'link': {
        return (
          <Link
            className="underline"
            href={message.content}
            target="_blank"
            rel="noreferrer">
            {message.content}
          </Link>
        )
      }
      case 'client-data': {
        try {
          const clientData = ClientDataSchema.parse(JSON.parse(message.content))

          return (
            <div className="flex flex-col items-stretch gap-10">
              <p className="c-b4 text-[#303030]">
                {clientData.name}님으로부터
                <br />
                캐릭터 콜라보 문의가 들어왔어요 😄
              </p>
              <p
                className="c-h8 cursor-pointer text-[#3366b6]"
                onClick={() => setState(clientData)}>
                내용 확인하기
              </p>
            </div>
          )
        } catch (e) {
          console.log(e)
          return null
        }
      }
      default: {
        return null
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message, fileSize])
  return (
    <div
      className={cn(
        'c-b4 overflow-hidden	text-ellipsis p-16',
        {
          'rounded-r-20 rounded-bl-20 bg-[#0000000A]': variant === 'left',
          'rounded-b-20 rounded-tl-20 bg-[#3366B633]': variant === 'right',
        },
        className,
      )}>
      {content}
    </div>
  )
}
