import { SvgIconProps } from '@/types/helpers'

export default function CloudDownload(props: SvgIconProps) {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="#C2C2C2"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M20.9265 19.4455C21.0983 19.5683 21.3156 19.6205 21.516 19.5538C24.9929 18.3962 27.5 15.1159 27.5 11.25C27.5 6.41751 23.5825 2.5 18.75 2.5C15.1385 2.5 12.038 4.68803 10.7018 7.8108C10.0877 7.6091 9.43158 7.5 8.75 7.5C5.29822 7.5 2.5 10.2982 2.5 13.75C2.5 17.0048 4.98793 19.6785 8.16578 19.9731C8.35208 19.9903 8.53113 19.9105 8.667 19.7819C9.20831 19.2695 9.8957 18.9277 10.628 18.8028C10.9682 18.7448 11.25 18.4702 11.25 18.125V16.25C11.25 14.1789 12.9289 12.5 15 12.5C17.0711 12.5 18.75 14.1789 18.75 16.25V18.125C18.75 18.4702 19.0317 18.7447 19.3721 18.8017C19.9215 18.8936 20.4543 19.1082 20.9265 19.4455Z"
        fill="currentFill"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.1339 21.6161C11.6457 21.128 10.8543 21.128 10.3661 21.6161C9.87796 22.1043 9.87796 22.8957 10.3661 23.3839L14.1161 27.1339C14.3592 27.377 14.6776 27.499 14.9963 27.5C14.9975 27.5 14.9988 27.5 15 27.5C15.0012 27.5 15.0025 27.5 15.0037 27.5C15.1719 27.4995 15.3322 27.4658 15.4785 27.4052C15.6259 27.3442 15.764 27.2537 15.8839 27.1339L19.6339 23.3839C20.122 22.8957 20.122 22.1043 19.6339 21.6161C19.1457 21.128 18.3543 21.128 17.8661 21.6161L16.25 23.2322V16.25C16.25 15.5596 15.6904 15 15 15C14.3096 15 13.75 15.5596 13.75 16.25V23.2322L12.1339 21.6161Z"
        fill="currentFill"
      />
    </svg>
  )
}
