import { createEnum } from '@/libraries/zod/createEnum'
import { z } from 'zod'

export const Paginated = <T>(type: z.Schema<T>) =>
  z.object({
    data: type,
    meta: z.object({
      nextCursor: z.string().nullable(),
    }),
  })

export const OffsetPaginated = <T>(type: z.Schema<T>) =>
  z.object({
    data: z.array(type),
    meta: z.object({
      totalPages: z.number(),
    }),
  })

export interface PaginatedList<T> {
  data: T
  meta: {
    nextCursor: string | null
  }
}

export type OffsetPaginatedList<T> = {
  data: T
  meta: {
    totalPages: number
    totalItems?: number
  }
}

export const ApplicationFieldSchema = z.object({
  value: z
    .string()
    .or(z.array(z.object({ url: z.string(), name: z.string() }))),
  label: z.string(),
  fieldType: z.string(),
})

export type ApplicationField = z.infer<typeof ApplicationFieldSchema>

export const WebLinkTypeEnum = createEnum(
  z.enum([
    'instagram',
    'youtube',
    'facebook',
    'twitter',
    'naver',
    'tiktok',
    'website',
    'smartstore',
  ]),
)

export const DataTableRowSchema = z.object({
  id: z.string(),
  title: z.string(),
  date: z.date(),
  applicants: z.number(),
  status: z.enum(['in-progress', 'upcoming', 'ended']),
  visibility: z.boolean().default(false),
  userInfo: z.object({
    name: z.string(),
    imgURL: z.string(),
  }),
  companyInfo: z.object({
    name: z.string(),
    imgURL: z.string(),
  }),
})

export type DataTableRow = z.infer<typeof DataTableRowSchema>

export const ApplicantDataTableRowSchema = z.object({
  index: z.number().default(0),
  id: z.string(),
  imageURL: z.string().nullable(),
  isAccepted: z.boolean().default(false),
  name: z.string(),
  date: z.date(),
  email: z.string(),
  phone: z.string(),
  fields: z.array(ApplicationFieldSchema),
})

export type ApplicantDataTableRow = z.infer<typeof ApplicantDataTableRowSchema>

export const EventCollaboStatusFilterSchema = z.preprocess(
  (value) => {
    if (value === undefined) return []
    if (typeof value === 'string') {
      return value
        .split(',')
        .filter((statuses) =>
          ['in-progress', 'result', 'ended'].includes(statuses),
        )
    }
    return value
  },
  z.array(z.enum(['in-progress', 'result', 'ended'])),
)

export const DataTableSearchParamsSchema = z.object({
  page: z.coerce.number().default(1),
  secondPage: z.coerce.number().default(1),
  perPage: z.coerce.number().default(10),
  secondPerPage: z.coerce.number().default(10),
  secondSearch: z.string().default(''),
  search: z.string().default(''),
  secondStatuses: EventCollaboStatusFilterSchema,
  statuses: EventCollaboStatusFilterSchema,
  sortBy: z.string().optional(),
  from: z.date().optional(),
  to: z.date().optional(),
  secondFrom: z.date().optional(),
  secondTo: z.date().optional(),
  sortOrder: z.enum(['asc', 'desc']).optional().default('desc'),
  visibility: z.boolean().default(false),
})

export type DataTableSearchParams = z.infer<typeof DataTableSearchParamsSchema>

export const ApplicantDataTableSearchParamsSchema =
  DataTableSearchParamsSchema.pick({
    page: true,
    perPage: true,
    search: true,
    sortBy: true,
    sortOrder: true,
  })

export type ApplicantDataTableSearchParams = z.infer<
  typeof ApplicantDataTableSearchParamsSchema
>
