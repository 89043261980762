import { z } from 'zod'

export function validatePartial(schema: z.AnyZodObject, fields: Array<string>) {
  return (data: z.infer<typeof schema>) => {
    try {
      const picks = fields.reduce(
        (acc, cv) => {
          acc[cv] = true
          return acc
        },
        {} as Record<string, true>,
      )
      const partialSchema = schema.pick(picks)
      partialSchema.parse(data)

      return true
    } catch {
      return false
    }
  }
}
