import {
  createWebSocketConnectionURL,
  markMessageAsRead,
} from '@/app/_messenger/actions'
import { useMessengerState } from '@/app/_messenger/hooks/useMessengerState'
import { useTriggerUpdateConversations } from '@/app/_messenger/hooks/useTriggerUpdateConversations'
import { useSession } from '@/auth/session.hook'
import { Message, PaginatedMessages } from '@/backend/models/Message'
import { getUserAvatarURL } from '@/libraries/avatar'
import { InfiniteData, useQueryClient } from '@tanstack/react-query'
import { useCallback, useEffect } from 'react'
import { useConversation } from './useConversations'

declare global {
  interface Window {
    wss: WebSocket
  }
}

type Msg = {
  conversationId: string
} & Omit<
  Message,
  'isSystemGenerated' | 'displayName' | 'imageURL' | 'createdAt'
>

export function message(msg: Msg) {
  if (window.wss && window.wss.readyState === WebSocket.OPEN) {
    window.wss.send(
      JSON.stringify({
        action: 'message',
        data: msg,
      }),
    )
  }
}

export function statusUpdate(
  status: 'typing' | 'none',
  conversationId: string,
) {
  if (window.wss && window.wss.readyState === WebSocket.OPEN) {
    window.wss.send(
      JSON.stringify({
        action: 'status',
        data: {
          conversationId,
          statusUpdate: status,
        },
      }),
    )
  }
}

export function channel(channelKey: string, action: 'join' | 'leave') {
  if (window.wss && window.wss.readyState === WebSocket.OPEN) {
    window.wss.send(
      JSON.stringify({
        action: 'channel',
        data: {
          action,
          channelKey,
        },
      }),
    )
  }
}

export function useInsertMessage() {
  const queryClient = useQueryClient()
  const conversation = useConversation()

  return useCallback(
    (msg: Msg) => {
      const userInfo = conversation.data?.users.find((u) => u.id === msg.userId)

      const newMessage: Message = {
        id: msg.id,
        userId: msg.userId,
        displayName: userInfo?.displayName || '',
        imageURL: userInfo?.imageURL || getUserAvatarURL(''),
        content: msg.content,
        fileName: msg.fileName,
        fileSize: msg.fileSize,
        fileType: msg.fileType,
        createdAt: new Date(),
        type: msg.type,
        isSystemGenerated: false,
        senderType: msg.senderType,
        isRead: false,
      }

      queryClient.setQueryData<InfiniteData<PaginatedMessages>>(
        ['messages', msg.conversationId],
        (old) => {
          if (!old) {
            return old
          }

          const existingMessage = old.pages[0].data.find(
            (m) => m.id === newMessage.id,
          )
          if (existingMessage) {
            if (existingMessage.type === 'client-data') {
              return {
                ...old,
                pages: [
                  {
                    ...old.pages[0],
                    data: old.pages[0].data.map((m) => {
                      if (m.id === newMessage.id) {
                        return newMessage
                      }
                      return m
                    }),
                  },
                  ...old.pages.slice(1),
                ],
              }
            }

            return old
          }

          return {
            ...old,
            pages: [
              {
                ...old.pages[0],
                data: [newMessage, ...old.pages[0].data],
              },
              ...old.pages.slice(1),
            ],
          }
        },
      )
    },
    [queryClient, conversation.data],
  )
}

export function useWebSocket(connectionURL: string) {
  const [, setState] = useMessengerState()
  const session = useSession()
  const triggerUpdate = useTriggerUpdateConversations()

  const insertMessage = useInsertMessage()

  const initWS = useCallback(
    (connURL?: string) => {
      const ws = new WebSocket(connURL || connectionURL)

      ws.addEventListener('message', (event) => {
        const data = JSON.parse(event.data)

        if (
          data.meta.eventSource === 'update' &&
          data.data === 'conversations'
        ) {
          triggerUpdate()
        }

        if (data.meta.eventSource === 'message') {
          const { status, chatMessage } = data.data

          if (status === 'pending') {
            insertMessage(chatMessage)
          }

          if (status === 'saved') {
            markMessageAsRead({
              id: chatMessage.id,
              conversationId: chatMessage.conversationId,
            }).then()
          }

          // eslint-disable-next-line no-console
          console.log('ws:message', status, chatMessage)
        }

        if (data.meta.eventSource === 'status') {
          const userId = session.data?.user.id
          if (!userId) return

          if (
            data.data.statusUpdate === 'typing' &&
            data.data.userId !== userId
          ) {
            setState((draft) => {
              draft.isOtherUserTyping = true
            })
          }

          if (
            data.data.statusUpdate === 'none' &&
            data.data.userId !== userId
          ) {
            setState((draft) => {
              draft.isOtherUserTyping = false
            })
          }
        }
      })

      ws.addEventListener('open', () => {
        // eslint-disable-next-line no-console
        console.log('ws:open')

        setState((draft) => {
          draft.isWssReady = true
        })
      })

      ws.addEventListener('close', () => {
        // eslint-disable-next-line no-console
        console.log('ws:close')

        setState((draft) => {
          draft.isWssReady = false
        })
      })

      return ws
    },
    [connectionURL, insertMessage, setState, triggerUpdate, session],
  )

  useEffect(() => {
    const interval = setInterval(async () => {
      if (window.wss && window.wss.readyState === WebSocket.CLOSED) {
        // eslint-disable-next-line no-console
        console.log('ws:reconnecting')
        const url = await createWebSocketConnectionURL()

        if (url) {
          window.wss = initWS(url)
        }
      }
    }, 45 * 1000)

    return () => {
      clearInterval(interval)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    window.wss = initWS()

    return () => {
      window.wss?.close()
    }
  }, [initWS])
}
