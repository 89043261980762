import { SvgIconProps } from '@/types/helpers'

export default function Close(props: SvgIconProps) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="white"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M4.47139 3.5286C4.21104 3.26825 3.78893 3.26825 3.52858 3.5286C3.26823 3.78895 3.26823 4.21106 3.52858 4.47141L7.05714 7.99997L3.52851 11.5286C3.26816 11.7889 3.26816 12.2111 3.52851 12.4714C3.78886 12.7318 4.21097 12.7318 4.47132 12.4714L7.99995 8.94278L11.5286 12.4714C11.7889 12.7318 12.211 12.7318 12.4714 12.4714C12.7317 12.2111 12.7317 11.789 12.4714 11.5286L8.94276 7.99997L12.4713 4.47141C12.7317 4.21106 12.7317 3.78895 12.4713 3.5286C12.211 3.26825 11.7889 3.26825 11.5285 3.5286L7.99995 7.05716L4.47139 3.5286Z"
        fill="currentFill"
      />
    </svg>
  )
}
