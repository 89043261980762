'use client'

import { getSessionAction } from '@/auth/getSessionAction'
import { useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'

export function useSession() {
  const query = useQuery({
    queryKey: ['session'],
    queryFn: async () => getSessionAction(),
  })

  return useMemo(() => {
    if (query.isLoading) {
      return {
        data: null,
        status: 'loading' as const,
      }
    }

    if (query.data && query.data !== null) {
      return {
        data: query.data,
        status: 'authenticated' as const,
      }
    }

    return {
      data: null,
      status: 'unauthenticated' as const,
    }
  }, [query.isLoading, query.data])
}
