import { useSession } from '@/auth/session.hook'
import { useMemo } from 'react'
import { useIntent } from '../common/Intent'
import { useConversation } from './useConversations'

export function useConversationUserInfo() {
  const session = useSession()
  const intent = useIntent()

  const conversation = useConversation()
  return useMemo(() => {
    if (!session.data) return []
    if (!conversation.data) return []

    if (intent === 'admin') {
      const creator = conversation.data.users[0]
      const user = conversation.data.users[1]
      // const creator = conversation.data.users.find(
      //   (u) => u.type === 'creator' || u.type === 'admin',
      // )
      // const user = conversation.data.users.find((u) => u.type === 'user')

      if (!creator || !user) return []

      return [creator, user]
    }

    const otherUser = conversation.data.users.find(
      (cu) => cu.id !== session.data.user.id,
    )

    if (!otherUser) return []

    return [otherUser]
  }, [session, conversation.data, intent])
}
