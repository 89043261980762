import { useMessengerState } from '@/app/_messenger/hooks/useMessengerState'
import { channel } from '@/app/_messenger/hooks/useWebSocket'
import { getUserAvatarURL } from '@/libraries/avatar'
import dayjs from 'dayjs'
import { useAtom } from 'jotai'
import Image from 'next/image'
import { clientDataPreviewAtom } from './ClientDataPreview'
import { useIntent } from './Intent'
import { ProfilePair } from './ProfilePair'

type Props = {
  id: string
  displayName: string
  lastMessage: string
  userIds: Array<string | null | undefined>
  imageURL: string
  updatedAt: Date

  adminIntentDisplayName: string
  imageURLs: Array<string | null | undefined>

  hasUnreadMessages: boolean
}

export function ConversationItem({
  id,
  displayName,
  adminIntentDisplayName,
  userIds,
  imageURLs,
  lastMessage,
  imageURL,
  hasUnreadMessages,
  updatedAt,
}: Props) {
  const [, setState] = useMessengerState()
  const [, setCDP] = useAtom(clientDataPreviewAtom)
  const intent = useIntent()

  return (
    <button
      onClick={() => {
        channel(id, 'join')
        setCDP(null)
        setState((draft) => {
          const url = new URL(window.location.href)
          url.searchParams.set('conversation', id)
          url.searchParams.set('conversationName', displayName)

          if (typeof window !== 'undefined')
            window.history.replaceState(null, '', url.toString())

          draft.selectedConversationId = id
        })
      }}
      type="button"
      className="relative flex flex-row items-center gap-10 border-none py-12 pl-24 pr-40 outline-none">
      {hasUnreadMessages && (
        <div className="absolute right-40 top-1/2 aspect-square w-8 -translate-y-1/2 animate-pulse rounded-full bg-[#60A5FA]" />
      )}
      {intent === 'admin' && (
        <ProfilePair
          userIds={userIds}
          images={imageURLs}
          fallback={getUserAvatarURL('')}
        />
      )}
      {intent === 'default' && (
        <Image
          alt={displayName}
          src={imageURL}
          width={61}
          height={61}
          className="!h-61 !w-61 shrink-0 rounded-full"
        />
      )}
      <div className="flex flex-col items-start px-10">
        <p className="c-h8">
          {intent === 'default' ? displayName : adminIntentDisplayName}
        </p>
        <p className="c-b4 line-clamp-1 w-250 text-ellipsis text-start text-txt-secondary">
          {lastMessage}
        </p>
        <p className="text-12 font-[400] leading-[19.2px] text-txt-secondary">
          {dayjs(updatedAt).fromNow()}
        </p>
      </div>
    </button>
  )
}
